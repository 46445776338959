<template>
  <section id="dashboard-ecommerce">
    <b-row v-if="this.userInfo.blocked_by_noshow">
      <b-col>
        <b-alert
          show
          dismissible
          variant="warning"
        >
          <h4 class="alert-heading">
            Attention
            <b-icon icon="exclamation-circle-fill" variant="warning" />
          </h4>
          <p class="p-2">
            Some of your lessons have been marked as "No show" you will not receive future invitations to lessons.
          </p>
        </b-alert>
      </b-col>
    </b-row>
    <b-row  v-if="!userData.confirmed_email || !userData.confirmed_phone">
      <b-col>
        <b-alert
          show
          dismissible
          variant="danger"
        >
          <h4 class="alert-heading">
            Warning
            <b-icon icon="exclamation-circle-fill" variant="danger" />
          </h4>
          <div class="p-2">
            <p >
              Your email and phone number are not confirmed click on the button to Confirm
            </p>
            <b-button
              class="mt-1"
              size="sm"
              variant="dark"
              href="/pages/account-setting"
            >
              Confirm
            </b-button>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="9"
        md="9"
        align-self="center"
      >
        <b-progress
          class="mb-2"
          height="2rem"
          variant="success"
          :max="progress_total"
        >
          <b-progress-bar
            :value="progress"
            :label="`${((progress / progress_total) * 100).toFixed(0)}%`"
          />
        </b-progress>
      </b-col>
      <b-col
        xl="3"
        md="3"
        align-self="center"
      >
        <b-button
          class="mb-2"
          variant="success"
          size="sm"
          href="/pages/account-setting"
        >
          Complete your profile
        </b-button>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="5"
        md="6"
      >
        <b-overlay
          :show="loadingRating"
          rounded="sm"
        >
          <b-card class="text-center">
            <template #header>
              <h2
                class="header-card"
              >
                Your Rating
              </h2>
            </template>
            <b-form-rating
              id="rating-inline"
              size="lg"
              no-border
              readonly
              inline
              show-value
              precision="1"
              variant="warning"
              :value="userInfo.rating"
              locale="en-US"
              class="star-rating mb-1"
            />
            <b-card-text
              class="text-center"
            >
              To improve your rating,  make more students happy
            </b-card-text>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        xl="4"
        md="6"
      >
        <b-overlay
          :show="loadingLessons"
          rounded="sm"
        >
          <b-card class="text-center">
            <template #header>
              <h2
                class="header-card"
              >
                Completed lessons
              </h2>
            </template>
            <div>
              <h1>
                <span class="count_lesson">{{ completedLessonsCount }}</span>
              </h1>
              <span>From December 18th, 2021</span>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col
        xl="3"
        md="6"
      >
        <b-overlay
          :show="loadingInvitations"
          rounded="sm"
        >
          <b-card
            class="text-center"
          >
            <template #header>
              <h2
                class="header-card"
              >
                Invitations
              </h2>
            </template>
            <div class="flex-invitations">
              <h1>
                <span class="count_accepted_invitations">{{ InvitationsConfirmedCount }}</span>
              </h1>
              <h1>
                <span class="count_invitations">/</span>
              </h1>
              <h1>
                <span class="count_not_accepted_invitations">{{ InvitationsUnconfirmedCount }}</span>
              </h1>
              <h1>
                <span class="count_invitations">/</span>
              </h1>
              <h1>
                <span class="count_invitations">{{ InvitationsTotalCount }}</span>
              </h1>
            </div>
            <span><br><br></span>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <!-- <b-col
        xl="6"
        md="6"
      >
        <b-card
          class="text-center"
        >
          <template #header>
            <h2
              class="header-card"
            >
              Your next lesson
            </h2>
          </template>
          <b-calendar
            block
            locale="en-US"
          />
        </b-card>
      </b-col> -->
      <b-col
        xl="5"
        md="6"
      >
        <b-overlay
          :show="loadingPaymentsAmount"
          rounded="sm"
        >
          <b-card class="text-center">
            <template #header>
              <h2
                class="header-card"
              >
                Money received
              </h2>
            </template>
            <div>
              <h1>
                Confirmed payment for<br>
              </h1>
              <h1>
                <span class="received_money">{{ paymentsAmount }}</span>
                <b-img
                  src="@/assets/images/icons/GoskateCoin.png"
                  width="40px"
                  height="40px"
                  class="pb-1"
                  fluid
                  alt="Responsive image"
                />
              </h1>
            </div>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BFormRating, BCardText, /* BCalendar, */ BOverlay, BProgress, BProgressBar, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormRating,
    BCardText,
    /* BCalendar, */
    BOverlay,
    BProgress,
    BProgressBar,
    BButton,
  },
  data() {
    return {
      progress_total: localStorage.getItem('total'),
      progress: localStorage.getItem('total_full'),
      progress_bar_search: localStorage.getItem('bar_show'),
      loadingInvitations: true,
      loadingRating: true,
      loadingLessons: true,
      loadingNoShow: true,
      loadingPaymentsAmount: true,
      data: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      userInfo: {
        rating: 0,
        full_name: null,
      },
      completedLessonsCount: 0,
      InvitationsTotalCount: 0,
      paymentsAmount: 0,
      InvitationsConfirmedCount: 0,
      InvitationsUnconfirmedCount: 0,
      controlledFileds: [
        'first_name',
        'last_name',
        'bio',
        'birth_date',
        'phone',
        'zipcode',
        'city',
        'state',
        'country',
        'address_street',
        'address_line',
        'parks',
        'accountFacebook',
        'accountInstagram',
      ],
    }
  },
  computed: {

  },
  beforeCreate() {
    // ? console.log('data')
    this.loadingRating = true
    this.$http.get('/api/profile/get_instructor_info')
      .then(res => {
        /*
        const data = JSON.parse(res.data)
        console.log(data)
        */
        this.userInfo = res.data
      })
      .catch(() => {})
      .then(() => {
        this.loadingRating = false
      })
    // ? console.log('data')
    this.loadingLessons = true
    this.$http.get('/api/dashboard/get_completed_lessons_count')
      .then(res => {
        /*
        const data = JSON.parse(res.data)
        console.log(data)
        */
        this.completedLessonsCount = res.data.lessons_count
      })
      .catch(() => {})
      .then(() => {
        this.loadingLessons = false
      })

    this.loadingInvitations = true
    this.$http.get('/api/dashboard/get_invitations_count')
      .then(res => {
        /*
        const data = JSON.parse(res.data)
        console.log(data)
        */
        this.InvitationsTotalCount = res.data.total_count
        this.InvitationsConfirmedCount = res.data.confirmed_count
        this.InvitationsUnconfirmedCount = res.data.unconfirmed_count
      })
      .catch(() => {})
      .then(() => {
        this.loadingInvitations = false
      })

    this.loadingPaymentsAmount = true
    this.$http.get('/api/dashboard/get_payments_amount')
      .then(res => {
        /*
        const data = JSON.parse(res.data)
        console.log(data)
        */
        this.paymentsAmount = res.data.total_amount
      })
      .catch(() => {})
      .then(() => {
        this.loadingPaymentsAmount = false
      })

    this.loadingNoShow = true
    this.$http.get('/api/profile/get_instructor_info')
      .then(res => {
        /*
        const data = JSON.parse(res.data)
        console.log(data)
        */
        this.noShow = res.data.blocked_by_noshow
      })
      .catch(() => {})
      .then(() => {
        this.loadingNoShow = false
      })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';

.block_confirm{
  color: white;
  font-weight:bold;
}

.received_money{
  color: green;
  font-size:1.6em;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight:bold;
}

.count_lesson{
  font-size:1.6em;
  padding-left: 45px;
  padding-right: 45px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight:bold;
}

.count_invitations{
  font-size:1.6em;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight:bold;
}

.count_accepted_invitations{
  font-size:1.6em;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight:bold;
  color: green;
}

.count_not_accepted_invitations{
  font-size:1.6em;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight:bold;
  color: red;
}

.flex-invitations{
  display: inline-flex;
}

.star-rating{
  font-size:2em;
}
</style>
